<template>
  <div style="position: relative">
    <div
      v-if="route == null"
      class="w-100 h-100 d-flex"
      id="flex-reverse-click-droite"
      style="position: absolute; top: 0%; right: 0%"
    >
      <div
        style="flex-basis: 50%"
        id="gauche"
        class="h-100 d-flex flex-column align-items-center justify-content-center voile"
        @click="
          () => {
            afficherProjets('perso');
          }
        "
      >
        <b class="titre align-middle">Projets personnels</b>
        <div class="mt-3"><span class="titre badge badge-light">{{nbProjetsPerso}}</span></div>
      </div>
      <hr v-if="afficherDroite == null" />
      <div
        style="flex-basis: 50%"
        class="h-100 d-flex flex-column align-items-center justify-content-center voile"
        @click="
          () => {
            afficherProjets('pro');
          }
        "
        id="droite"
      >
        <b class="titre">Projets scolaire / professionels</b>
        <div class="mt-3"><span class="titre badge badge-light">{{nbProjetsPro}}</span></div>
      </div>
    </div>
    <router-view ></router-view>
  </div>
</template>

<script src="./projet.index.js"></script>

<style scoped>
.titre {
  font-size: 300%;
  animation-name: grossir;
  animation-duration: 1s;
  animation-timing-function: ease;
  transition: all 1s;
}
hr {
  height: 100%;
  width: 4px;
  color: #000;
  background-color: #000;
  margin: 0%;
  border-top: 0px !important;
}

.voile {
  background-color: rgba(71, 159, 173, 0.43);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  transition: all 1s;
  z-index: 100;
}

.voile:hover {
  background-color: rgba(71, 159, 173, 0.9);
}

.voile:hover .titre {
  font-size: 400%;
}

.voile-autre {
  flex-basis: 0% !important;
}

.voile-autre-timeout {
  display: none !important;
}

.voile-clique {
  width: 100%;
  flex-basis: 100% !important;
}

.voile-vers-haut {
  transform: translateY(-100%);
}

.projet {
  animation-name: grossir;
  animation-duration: 1s;
  animation-timing-function: ease;
  transition: all 1s;
}

@keyframes grossir {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>