import axios from 'axios'
import communLangage from '@/commun/commun.langage.js';
export default {
     name: 'Projets',

     data() {
          return {
               projetEnCours: null,
               afficherDroite: null,

               projets: []
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },

          route() {
               return this.$route.path.split("/")[2] ?? null;
          },

          projetsAAfficher(){
               if(this.route == null) return this.projets;
               return this.projets.filter(x => x.type == this.route);
          },

          nbProjetsPerso(){
               return this.projets.filter(x => x.type == "perso")?.length;
          },

          nbProjetsPro(){
               return this.projets.filter(x => x.type == "pro")?.length;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/autre/autre.json').then(result => {
                    this.projetEnCours = this.langage == "fr" ? result.data.fr.enCoursCreation : result.data.en.enCoursCreation;

               });
                    axios.get(process.env.BASE_URL + 'assets/textes/projets/projets.json').then(result => {
                         
                         result.data.projets.forEach(element => {
                              var tmp = this.langage == "fr" ? element.fr : element.en;
                              tmp.code = element.code;
                              tmp.img = element.img == "" ? "point-dinterrogation.png" : element.img;
                              tmp.type = element.type;
                              this.projets.push(tmp)

                         });
                    });
          },

          afficherProjets(type) {
               const maPromesse = new Promise((resolve) => {
                    var id1 = type == "perso" ? "droite" : "gauche";
                    var id2 = type == "perso" ? "gauche" : "droite";

                    document.getElementById(id1).classList.add("voile-autre");

                    setTimeout(() => { document.getElementById(id1).classList.add("voile-autre-timeout"); if (id2 == "droite") document.getElementById('flex-reverse-click-droite').style.flexDirection = "row-reverse" }, 400);
                    document.getElementById(id2).classList.add("voile-clique");

                    setTimeout(() => { document.getElementById(id2).classList.add("voile-vers-haut") }, 900);
                    setTimeout(() => { resolve() }, 1700);

               })
               maPromesse.then(() => {
                    this.$router.push({name:`projets.type`,params:{type:type}});
               });


          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();

     }
}